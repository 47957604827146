@use './global' as global;

html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: global.$font-family;
  line-height: 1;
  color: global.$grey800;

  a {
    color: global.$grey800;
    line-height: 1;
    text-decoration: none;
    outline: none;
  }
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
    outline: none;
  }
  a:visited {
    color: global.$grey800;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  button {
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  font-family: inherit;
  letter-spacing: -0.02em;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
