@use "@/assets/styles/global" as global;

.container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
}

.container__loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  border: 8px solid global.$grey200;
  border-radius: 50%;
}
.container__loader div {
  top: -16px;
  left: -16px;

  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid global.$primary900;
  border-radius: 50%;
  animation: loader 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: global.$primary900 transparent transparent transparent;
}

.container__loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.container__loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.container__loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
