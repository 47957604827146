@use "../global" as global;

.footer-navigation-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    max-width: 1920px;
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 60px 100px 100px;

    &__left {
      display: flex;
      flex: 1;
      align-items: flex-start;
      gap: 60px;

      div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        ul {
          display: flex;
          align-items: center;
          li {
            line-height: 24px;
            display: flex;
            align-items: center;
            gap: 10px;
            white-space: nowrap;

            &:last-child {
              &::after {
                content: "";
              }
            }

            &::after {
              content: "|";
              font-size: 12px;
              margin-right: 10px;
            }

            p {
              &.bold {
                font-weight: 700;
              }

              &:first-child {
                color: global.$grey800;
              }

              &:last-child {
                color: global.$grey600;

                a {
                  color: global.$grey600;
                }
              }
            }
          }
        }

        p {
          line-height: 24px;
        }

        nav {
          margin-top: 40px;
          display: flex;
          gap: 40px;
          align-items: center;

          a {
            line-height: 24px;
            &.bold {
              font-weight: 700;
            }
          }
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
    }
  }
}

@include global.mobile {
  .footer-navigation-wrapper {
    .container {
      height: auto;
      flex-direction: column;
      padding: 60px 24px 130px;

      &__left {
        flex-direction: column;
        gap: 24px;

        div {
          gap: 24px;
          ul {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            li {
              &::after {
                display: none;
              }
            }
          }
          nav {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            margin-top: 0;
          }
        }
      }
      &__right {
        margin-top: 24px;
      }
    }
  }
}
