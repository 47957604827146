@use "../global" as global;

.header-navigation-wrapper {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;

  width: 100%;
  display: flex;
  justify-content: center;
  background: global.$white;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .container {
    max-width: 1920px;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;

    & h1 {
      cursor: pointer;
      width: 107px;
      height: 40px;
    }

    & .header__nav {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      align-items: center;
      gap: 80px;

      p {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        cursor: pointer;
      }

      a {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }

      &__event {
        position: relative;
        height: 100%;

        &__link {
          position: absolute;
          left: 0;
          top: 70px;
          width: max-content;
          background: global.$white;
          border: 1px solid #eee;
          padding: 8px;
          border-radius: 8px;
          box-shadow: 0 4px 16px 0 rgba(30, 32, 37, 0.1);

          a {
            padding: 8px 16px;
            border-radius: 8px;
            line-height: 26px;
            word-break: keep-all;
            font-weight: 400 !important;
            font-size: 16px;
            color: global.$grey600 !important;

            &:hover {
              background-color: global.$grey70;
            }
          }
        }
      }
    }
  }

  &.transparent {
    background-color: transparent;
    a,
    p {
      color: global.$white !important;
    }
  }

  &.hideHeader {
    top: -80px;
  }
}

@include global.tablet {
  .header-navigation-wrapper {
    .container {
      height: 100px;
    }
  }
}

@include global.mobile {
  .header-navigation-wrapper {
    .container {
      max-width: 100vw;
      height: 70px;
      padding: 20px 24px;

      & h1 {
        cursor: pointer;
        width: 72px;
        height: 29px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
