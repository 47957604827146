@use '../global' as global;

.page-not-found {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 36px;
  padding: 0;
  width: 100vw;
  min-height: 100vh;
  text-align: center;

  .wrap {
    transform: translateY(40px);

    .content {
      margin: 20px 0;

      h2 {
        line-height: 56px; /* 140% */
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        color: global.$grey800;
      }

      p {
        margin-top: 20px;
        padding: 0 20px;
        line-height: 32px;
        word-break: keep-all;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.4px;
        color: global.$grey600;
      }
    }

    button {
      padding: 12px 16px;
      border-radius: 8px;
      background-color: #565966;
      line-height: 24px;
      font-size: 16px;
      font-weight: 800;
      color: global.$white;
    }
  }
}
